@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* We'll handle padding differently */
  padding-top: 0;
}

/* Fixed header spacing - apply to all direct children of body instead */
body > div#root > * {
  padding-top: 42px; /* Match the height of the header */
}

/* Make sure all containers that should be below the header use this class */
.content-container {
  padding-top: 42px;
  margin-top: 0;
}

/* Proper spacing for page titles */
h1.text-2xl {
  margin-top: 65px; /* Increased margin to clear the header */
}

/* Add spacing to inventory and supplier pages */
.container.mx-auto.px-4 {
  padding-top: 2.5rem; /* Add extra top padding to all containers */
}

/* Fix the flex layout container for the title and button */
.flex.flex-col.sm\\:flex-row {
  margin-top: 2rem; /* Add margin to the flex container */
  padding-top: 1rem; /* Add padding to the flex container */
}

/* Ensure buttons in the header area are visible */
.flex.flex-col.sm\\:flex-row > button {
  position: relative; /* Ensure proper stacking context */
  z-index: 10; /* Increase z-index to prevent being covered */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-in;
}

/* Line clamp utilities */
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Z-index utilities */
.-z-10 {
  z-index: -10;
}

/* Ensures content stays below the header */
main {
  padding-top: 0 !important; /* Override any existing padding */
  margin-top: 0 !important;
} 